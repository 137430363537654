<script setup lang="ts">
import { eventEmitterUtil } from '../../utils/EventEmitterUtil.ts';
import { onMounted, onUnmounted, reactive } from 'vue';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '../ui/dialog';
import { useSettingsStore } from '../../stores/SettingsStore.ts';
import { Button } from '../ui/button';
import { CommandEmpty, CommandGroup, CommandItem, CommandList } from '../ui/command';
import {
    TagsInput,
    TagsInputInput,
    TagsInputItem,
    TagsInputItemDelete,
    TagsInputItemText,
} from '../ui/tags-input';
import {
    ComboboxAnchor,
    ComboboxContent,
    ComboboxInput,
    ComboboxPortal,
    ComboboxRoot,
} from 'radix-vue';
import { computed } from 'vue';
import { programmingLanguagesList } from '../../types/ProgrammingLanguage.ts';
import HotkeyIndicatorInline from '../hotkey/HotkeyIndicatorInline.vue';
import { Hotkey } from '../../types/Hotkey.ts';

const settingsStore = useSettingsStore();

interface ComponentState {
    isOpen: boolean;
    searchTerm: string;
    modelValue: string[];
    dropdownOpen: boolean;
}

const state: ComponentState = reactive({
    isOpen: false,
    searchTerm: '',
    modelValue: [],
    dropdownOpen: false,
});

const filteredLanguages = computed(() =>
    Object.values(programmingLanguagesList).filter((p) => !state.modelValue.includes(p.value)),
);

const openModal = () => {
    state.isOpen = true;
    state.modelValue = settingsStore.dynamicProgrammingLanguages;
};

const saveAndClose = () => {
    settingsStore.dynamicProgrammingLanguages = state.modelValue;
    state.isOpen = false;
};

onMounted(() => {
    eventEmitterUtil.addListener('openDynamicLanguageSwitchingDialog', openModal);
});

onUnmounted(() => {
    eventEmitterUtil.removeListener('openDynamicLanguageSwitchingDialog', openModal);
});
</script>

<template>
    <Dialog v-model:open="state.isOpen">
        <DialogContent class="rounded">
            <DialogHeader class="mb-4">
                <DialogTitle>Dynamic language switching</DialogTitle>
            </DialogHeader>

            <p class="text-sm dark:text-gray-400">
                Please select the programming language(s) you want to switch between:
            </p>

            <div>
                <TagsInput class="gap-0 px-0" :model-value="state.modelValue">
                    <div class="flex flex-wrap items-center gap-2 px-2 pb-0">
                        <TagsInputItem v-for="item in state.modelValue" :key="item" :value="item">
                            <TagsInputItemText />
                            <TagsInputItemDelete />
                        </TagsInputItem>
                    </div>

                    <ComboboxRoot
                        v-model="state.modelValue"
                        v-model:open="state.dropdownOpen"
                        v-model:search-term="state.searchTerm"
                        class="z-100 w-full px-2"
                    >
                        <ComboboxAnchor as-child>
                            <ComboboxInput
                                placeholder="Programming languages..."
                                as-child
                                @click="state.dropdownOpen = true"
                            >
                                <TagsInputInput
                                    class="w-full rounded bg-gray-800 p-1.5"
                                    :class="state.modelValue.length > 0 ? 'mt-2' : ''"
                                    @keydown.enter.prevent
                                />
                            </ComboboxInput>
                        </ComboboxAnchor>

                        <ComboboxPortal>
                            <ComboboxContent class="absolute z-[100]">
                                <CommandList
                                    position="popper"
                                    class="mt-2 w-[--radix-popper-anchor-width] rounded-md border bg-popover text-popover-foreground shadow-md outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2"
                                >
                                    <CommandEmpty />
                                    <CommandGroup>
                                        <CommandItem
                                            v-for="language in filteredLanguages"
                                            :key="language.value"
                                            :value="language.label"
                                            @select.prevent="
                                                (ev) => {
                                                    if (typeof ev.detail.value === 'string') {
                                                        state.searchTerm = '';
                                                        state.modelValue.push(ev.detail.value);
                                                    }

                                                    if (filteredLanguages.length === 0) {
                                                        state.dropdownOpen = false;
                                                    }
                                                }
                                            "
                                        >
                                            {{ language.label }}
                                        </CommandItem>
                                    </CommandGroup>
                                </CommandList>
                            </ComboboxContent>
                        </ComboboxPortal>
                    </ComboboxRoot>
                </TagsInput>
            </div>

            <p class="text-sm dark:text-gray-400">
                You can use the configured hotkey
                <HotkeyIndicatorInline
                    :hotkey="Hotkey.ChangeLanguage"
                    class="h-[1.25rem] items-center justify-center overflow-hidden rounded border border-muted-foreground px-1 py-0.5 text-xs tracking-wide"
                />
                to switch between the selected programming languages after saving.
            </p>

            <DialogFooter>
                <Button type="button" @click="saveAndClose">
                    <span>Save</span>
                </Button>
            </DialogFooter>
        </DialogContent>
    </Dialog>
</template>
