import { Emitter } from 'strict-event-emitter';
import { Hotkey } from '../types/Hotkey.ts';

type Events = {
    // Auth
    login: [];
    logout: [];
    // Electron
    hotkeyPress: [Hotkey];
    onRequestPermissionStatusResponse: [{ screen: boolean }];
    // Overlays
    showTrialInputWarning: [inputSourceName: string | null, imageData: string, solveId: string];
    showFirstTimeHideWarning: [];
    showContentProtectionWarning: [];
    showSolvePreview: [imageData: string];
    openShortcutConfigurationDialog: [];
    openModelSelectionDialog: [];
    openDynamicLanguageSwitchingDialog: [];
};

export const eventEmitterUtil = new Emitter<Events>();
