import { Hotkey } from '../types/Hotkey.ts';

export const HOTKEY_LABELS = {
    [Hotkey.Up]: 'Up',
    [Hotkey.Down]: 'Down',
    [Hotkey.Left]: 'Left',
    [Hotkey.Right]: 'Right',
    [Hotkey.ChangeLanguage]: 'Change Language',
    [Hotkey.Solve]: 'Solve',
    [Hotkey.Reset]: 'Reset',
    [Hotkey.Hide]: 'Hide',
    [Hotkey.Quit]: 'Quit',
};
