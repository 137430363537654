import { acceptHMRUpdate, defineStore } from 'pinia';
import { LiveBanner } from '../types/app/LiveBanner.ts';

interface State {
    isFullLoading: boolean;
    isInsideWebView: boolean;
    isContentProtectionEnabled: boolean;
    isTaskbarIconVisible: boolean;
    liveBanners: LiveBanner[];
    liveBannersLoaded: boolean;
    activeDynamicLanguageIndex: number;
}

export const useUiStore = defineStore('ui', {
    state: (): State => {
        return {
            isFullLoading: false,
            isInsideWebView: false,
            isContentProtectionEnabled: true,
            isTaskbarIconVisible: true,
            liveBanners: [],
            liveBannersLoaded: false,
            activeDynamicLanguageIndex: 0,
        };
    },
    persist: {
        storage: sessionStorage,
    },
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useUiStore, import.meta.hot));
}
