import { getOs } from '../../utils/AppUtil.ts';
import { Hotkey } from '../../types/Hotkey.ts';
import { SHORTCUT_CONFIGURATION } from '../../constants/ShortcutConfiguration.ts';
import { computed } from 'vue';
import { acceleratorToLabel } from '../../utils/HotkeyUtil.ts';
import { useSettingsStore } from '../../stores/SettingsStore.ts';

const getHotkeyLabel = (hotkey: Hotkey) => {
    if (!(hotkey in SHORTCUT_CONFIGURATION)) {
        return hotkey;
    }

    const settingsStore = useSettingsStore();

    if (!(hotkey in settingsStore.hotkeys)) {
        return hotkey;
    }

    let output: string;
    if (getOs() === 'windows') {
        output = settingsStore.hotkeys[hotkey]
            .replace('CmdOrCtrl', 'ctrl')
            .replace('CommandOrControl', 'ctrl');
    } else {
        output = settingsStore.hotkeys[hotkey]
            .replace('CmdOrCtrl', 'cmd')
            .replace('CommandOrControl', 'cmd');
    }

    return acceleratorToLabel(output);
};

const indicatorMinWidth = computed(() => ({
    'min-w-11': getOs() === 'windows',
    'min-w-9': getOs() !== 'windows',
}));

export { getHotkeyLabel, indicatorMinWidth };
