enum Hotkey {
    Up = 'UP',
    Down = 'DOWN',
    Left = 'LEFT',
    Right = 'RIGHT',
    Solve = 'SOLVE',
    Reset = 'RESET',
    Hide = 'HIDE',
    Quit = 'QUIT',
    ChangeLanguage = 'CHANGE_LANGUAGE',
}

export { Hotkey };
