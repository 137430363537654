import { createTextToast } from '../../utils/ToastUtil.ts';
import { useSettingsStore } from '../../stores/SettingsStore.ts';
import { useUiStore } from '../../stores/UiStore.ts';
import { ProgrammingLanguage } from '../../types/ProgrammingLanguage.ts';

export const dynamicLanguageChangeAction = async () => {
    const settingsStore = useSettingsStore();
    const uiStore = useUiStore();

    const dynamicLanguagesLength = settingsStore.dynamicProgrammingLanguages.length;
    if (settingsStore.dynamicProgrammingLanguages.length < 1) {
        createTextToast(
            'No dynamic languages configured',
            'Visit the options menu (Options -> Dynamic language switching) to configure dynamic language switching.',
            'destructive',
            true,
        );

        return;
    }

    const nextLanguage =
        settingsStore.dynamicProgrammingLanguages[
            uiStore.activeDynamicLanguageIndex % dynamicLanguagesLength
        ];

    settingsStore.programmingLanguage = nextLanguage as ProgrammingLanguage;

    createTextToast('New programming language activated:', nextLanguage, 'success', true);

    uiStore.activeDynamicLanguageIndex = uiStore.activeDynamicLanguageIndex + 1;
};
