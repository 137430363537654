<script setup lang="ts">
import { ExclamationTriangleIcon, InfoCircledIcon } from '@radix-icons/vue';
import { useUserInfoStore } from '../stores/UserInfoStore.ts';
import { useSettingsStore } from '../stores/SettingsStore.ts';
import { Card, CardContent, CardHeader, CardTitle } from '../components/ui/card';
import { SubscriptionTier } from '../types/user/SubscriptionTier.ts';
import { useUiStore } from '../stores/UiStore.ts';
import { useHostStore } from '../stores/HostStore.ts';
import { Hotkey } from '../types/Hotkey.ts';
import { router, ROUTES } from '../router.ts';
import { onMounted, onUnmounted } from 'vue';
import { eventEmitterUtil } from '../utils/EventEmitterUtil.ts';
import { startSubscriptionProcessAction } from '../actions/app/StartSubscriptionProcessAction.ts';
import { useClientStore } from '../stores/ClientStore.ts';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../components/ui/tooltip';
import { hideO1AdAction } from '../actions/app/HideO1AdAction.ts';
import { Button } from '../components/ui/button';
import { X as XIcon } from 'lucide-vue-next';

const userInfoStore = useUserInfoStore();
const hostStore = useHostStore();
const clientStore = useClientStore();
const settingsStore = useSettingsStore();
const uiStore = useUiStore();

const onHotkeyPress = (hotkey: Hotkey) => {
    if (hotkey === Hotkey.Right && clientStore.activeSolveId) {
        router.push({
            name: ROUTES.solveAlgorithmSelection,
            params: { solveId: clientStore.activeSolveId },
        });
    }
};

const onSubscriptionButton = () => {
    startSubscriptionProcessAction();
};

const hideO1Ad = () => {
    hideO1AdAction();
};

onMounted(() => {
    eventEmitterUtil.addListener('hotkeyPress', onHotkeyPress);
});

onUnmounted(() => {
    eventEmitterUtil.removeListener('hotkeyPress', onHotkeyPress);
});
</script>

<template>
    <div class="custom-scrollbar flex-shrink flex-grow overflow-y-scroll p-2">
        <div v-if="uiStore.liveBanners.length > 0" class="mb-5">
            <Card
                v-for="(liveBanner, index) in uiStore.liveBanners"
                :key="index"
                class="mb-2 rounded bg-blue-700"
            >
                <CardHeader class="p-4 text-lg">{{ liveBanner.title }}</CardHeader>
                <CardContent class="p-4 pt-0 text-sm">{{ liveBanner.description }}</CardContent>
            </Card>
        </div>

        <!-- o1 update card -->
        <Card
            v-if="settingsStore.showO1Ad"
            class="relative mx-[1px] mb-2 select-none rounded border-2 border-blue-600"
        >
            <Button
                variant="outline"
                size="icon"
                class="absolute right-2 top-2 border-none"
                @click="hideO1Ad"
            >
                <XIcon class="h-4 w-4" />
            </Button>
            <CardHeader class="pb-4">
                <CardTitle class="text-lg">🆕 "OpenAI o1-mini" Beta Support Added</CardTitle>
            </CardHeader>
            <CardContent class="text-sm"
                >Visit the options menu (Options -> Configure AI model) to try this new improved
                model.</CardContent
            >
        </Card>

        <!-- Web view card -->
        <Card
            v-if="uiStore.isInsideWebView"
            class="mx-[1px] select-none rounded border-2 border-green-600"
        >
            <CardHeader class="pb-4">
                <CardTitle class="text-lg">Web view</CardTitle>
            </CardHeader>
            <CardContent class="text-sm">
                All output from the desktop app will become visible here after you press solve. The
                web view can only be controlled from the desktop app.
                <div class="mt-4 text-muted-foreground">
                    <InfoCircledIcon class="mb-1 inline text-yellow-600" />
                    If you are using the web view during an interview, make sure to hide the desktop
                    client by going to "Options -> Hide App" or by pressing the configured hotkey.
                </div>
            </CardContent>
        </Card>

        <!--            &lt;!&ndash; MacOS content protection warning &ndash;&gt;-->
        <!--            <Card-->
        <!--                v-if="getOs() === 'macos' && !uiStore.isInsideWebView"-->
        <!--                class="mb-2 rounded border-2 border-destructive"-->
        <!--            >-->
        <!--                <CardHeader class="p-4 text-lg">-->
        <!--                    <CardTitle class="text-lg">Warning</CardTitle>-->
        <!--                </CardHeader>-->
        <!--                <CardContent class="p-4 pt-0 text-sm text-muted-foreground">-->
        <!--                    Due to an <a href="https://github.com/electron/electron/issues/19880" class="hover:underline text-blue-500">unresolved bug</a> in Electron, some WebRTC-based screen-sharing software may be able to see Leetcode Wizard on MacOS while sharing your full screen. We recommend using the web view during interviews for 100% safety when using MacOS.-->
        <!--                </CardContent>-->
        <!--            </Card>-->

        <!-- Trial subscription card -->
        <Card
            v-if="
                !uiStore.isInsideWebView &&
                userInfoStore.subscriptionTier === SubscriptionTier.Trial
            "
            class="mx-[1px] select-none rounded border-2 border-yellow-600"
        >
            <CardHeader class="pb-4">
                <CardTitle class="text-lg">Trial subscription</CardTitle>
            </CardHeader>
            <CardContent class="text-sm">
                You have
                <span class="font-bold">{{
                    userInfoStore.creditsRemaining.toLocaleString(undefined, {
                        minimumFractionDigits: 1,
                        maximumFractionDigits: 1,
                    })
                }}</span>
                trial credits remaining. Each
                <TooltipProvider :delay-duration="100">
                    <Tooltip>
                        <TooltipTrigger><span class="underline">action</span></TooltipTrigger>
                        <TooltipContent :side="'bottom'">
                            An action can be defined as: requesting algorithms, code, tests, or a
                            complexity analysis.
                        </TooltipContent>
                    </Tooltip>
                </TooltipProvider>
                costs about 1 trial credit but this can be different depending on input and output
                size.
                <div class="mt-4 text-muted-foreground">
                    <InfoCircledIcon class="mb-1 inline text-yellow-600" />
                    The trial mode of Leetcode Wizard only works on
                    <a href="https://leetcode.com/problemset/" target="_blank" class="underline"
                        >LeetCode.com</a
                    >. Our Pro version works on all input sources. Click
                    <span class="underline" @click="onSubscriptionButton">here</span>
                    to subscribe.
                </div>
            </CardContent>
        </Card>

        <!-- Pro subscription card -->
        <Card
            v-if="
                !uiStore.isInsideWebView && userInfoStore.subscriptionTier === SubscriptionTier.Pro
            "
            class="mx-[1px] select-none rounded border-2 border-green-600"
        >
            <CardHeader class="pb-4">
                <CardTitle class="text-lg">Pro subscription</CardTitle>
            </CardHeader>
            <CardContent class="text-sm"> You have an active subscription. </CardContent>
        </Card>

        <!-- Input source card -->
        <Card
            v-if="!uiStore.isInsideWebView"
            class="mx-[1px] mt-2 select-none rounded"
            :class="{
                'border-2 border-green-600': hostStore.selectedSourceId,
                'border-2 border-red-600': !hostStore.selectedSourceId,
            }"
        >
            <CardHeader class="pb-4">
                <CardTitle class="text-lg">
                    <ExclamationTriangleIcon
                        v-if="!hostStore.selectedSourceName"
                        class="me-1 inline h-4 w-4 text-destructive"
                    />
                    Input source
                </CardTitle>
            </CardHeader>
            <CardContent class="text-sm">
                <div v-if="hostStore.selectedSourceId">
                    <span class="text-md font-bold"> {{ hostStore.selectedSourceName }}</span>
                    <div class="mt-4 text-muted-foreground">
                        You can change the input source in the options menu.
                    </div>
                </div>
                <div v-else>
                    No input source selected, please select an input source in the options menu.
                </div>
                <div class="mt-4 text-muted-foreground">
                    <InfoCircledIcon class="mb-1 inline text-yellow-600" />
                    Please make sure the Leetcode problem description and initial code are fully
                    visible in the configured input source window before pressing solve to guarantee
                    the best possible output.
                </div>
            </CardContent>
        </Card>

        <!-- Settings card -->
        <Card
            v-if="!uiStore.isInsideWebView"
            class="mx-[1px] mt-2 select-none rounded border-2 border-green-600"
        >
            <CardHeader class="pb-4">
                <CardTitle class="text-lg"> Settings </CardTitle>
            </CardHeader>
            <CardContent class="text-sm">
                <span class="text-md">
                    <strong>Programming language:</strong> {{ settingsStore.programmingLanguage
                    }}<br />
                    <strong>AI model:</strong> {{ settingsStore.activeAiModel }}
                </span>
                <div class="mt-4 text-muted-foreground">
                    All settings can be changed from the options menu.
                </div>
            </CardContent>
        </Card>
    </div>
</template>
