enum ProgrammingLanguage {
    'C++' = 'C++',
    'Java' = 'Java',
    'Python' = 'Python',
    'Python3' = 'Python3',
    'MySQL' = 'MySQL',
    'MS SQL Server' = 'MS SQL Server',
    'Oracle SQL' = 'Oracle SQL',
    'C' = 'C',
    'C#' = 'C#',
    'JavaScript' = 'JavaScript',
    'Ruby' = 'Ruby',
    'Bash' = 'Bash',
    'Swift' = 'Swift',
    'Go' = 'Go',
    'Scala' = 'Scala',
    'Kotlin' = 'Kotlin',
    'Rust' = 'Rust',
    'PHP' = 'PHP',
    'TypeScript' = 'TypeScript',
    'Racket' = 'Racket',
    'Erlang' = 'Erlang',
    'Elixir' = 'Elixir',
    'Dart' = 'Dart',
    'PostgreSQL' = 'PostgreSQL',
    'Pandas' = 'Pandas',
    'React' = 'React',
    'Vanilla JS' = 'Vanilla JS',
}

const programmingLanguagesList = [
    { value: 'C++', label: 'C++' },
    { value: 'Java', label: 'Java' },
    { value: 'Python', label: 'Python' },
    { value: 'Python3', label: 'Python3' },
    { value: 'MySQL', label: 'MySQL' },
    { value: 'MS SQL Server', label: 'MS SQL Server' },
    { value: 'Oracle SQL', label: 'Oracle SQL' },
    { value: 'C', label: 'C' },
    { value: 'C#', label: 'C#' },
    { value: 'JavaScript', label: 'JavaScript' },
    { value: 'Ruby', label: 'Ruby' },
    { value: 'Bash', label: 'Bash' },
    { value: 'Swift', label: 'Swift' },
    { value: 'Go', label: 'Go' },
    { value: 'Scala', label: 'Scala' },
    { value: 'Kotlin', label: 'Kotlin' },
    { value: 'Rust', label: 'Rust' },
    { value: 'PHP', label: 'PHP' },
    { value: 'TypeScript', label: 'TypeScript' },
    { value: 'Racket', label: 'Racket' },
    { value: 'Erlang', label: 'Erlang' },
    { value: 'Elixir', label: 'Elixir' },
    { value: 'Dart', label: 'Dart' },
    { value: 'PostgreSQL', label: 'PostgreSQL' },
    { value: 'Pandas', label: 'Pandas' },
    { value: 'React', label: 'React' },
    { value: 'Vanilla JS', label: 'Vanilla JS' },
];

export { ProgrammingLanguage, programmingLanguagesList };
